export const DEALERS_REQUEST_FIELDS = [
  'id',
  'latitude',
  'longitute',
  'name',
  'address_1',
  'address_2',
  'address_3',
  'code',
  'google_address',
  'main_number',
  'main_image',
  'operating_hours.operating_hours_id.id',
  'operating_hours.operating_hours_id.day_of_the_week',
  'operating_hours.operating_hours_id.opening_time',
  'operating_hours.operating_hours_id.closing_time',
  'city',
  'city.name',
  'city.id',
  'city.name',
  'city.province.id',
  'city.province.name',
  'city.country.id',
  'city.country.name',
  'cms_new_dealer_code',
  'cms_used_dealer_code',
  'cms_new_leads',
  'cms_used_leads',
  'cms_car_finder_leads',
  'cms_general_leads',
  'cms_parts_leads',
  'cms_service_leads',
  'cms_source_used_cars',
  'province',
  'province.name',
  'enquiry_form_services',
  'awards.*',
  'peerview_item_id',
  'peerview_type',
  'dealership_team',
  'description',
  'warranties.*',
  'warranties.warranties_id.title',
  'warranties.warranties_id.description',
  'warranties.warranties_id.image',
  'awards.awards_id.text',
  'awards.awards_id.url',
  'awards.awards_id.image',
  'awards.awards_id.description',
  'dealership_awards_title',
  'pre_owned_call_to_action.*',
  'call_to_actions.call_to_actions_id.text',
  'call_to_actions.call_to_actions_id.url',
  'call_to_actions.call_to_actions_id.type',
  'call_to_actions.call_to_actions_id.heading',
  'call_to_actions.call_to_actions_id.subheading',
  'call_to_actions.call_to_actions_id.image',
  'call_to_actions.call_to_actions_id.icon',
  'header_call_to_actions.*',
  'header_call_to_actions.call_to_actions_id.text',
  'header_call_to_actions.call_to_actions_id.url',
  'header_call_to_actions.call_to_actions_id.type',
  'header_call_to_actions.call_to_actions_id.heading',
  'header_call_to_actions.call_to_actions_id.subheading',
  'header_call_to_actions.call_to_actions_id.image',
  'header_call_to_actions.call_to_actions_id.icon',
  'trade_in_call_to_action.text',
  'trade_in_call_to_action.url',
  'trade_in_call_to_action.type',
  'trade_in_call_to_action.heading',
  'trade_in_call_to_action.subheading',
  'trade_in_call_to_action.image',
  'trade_in_call_to_action.icon',
  'section_order',
  'sort',
];

export const dealerLocation = {
  lat: -26.126461,
  lng: 27.846852,
};

export const CLOSE_DEALERS_QUANTITY_TO_SHOW = 3;

export const FILTER_PUBLISHED_DEALERS = {
  status: { _eq: 'published' },
};
